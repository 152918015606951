import _ from 'lodash';
import { apiRequest, API_SUCCESS, API_ERROR } from 'redux/actions/api';
import { setAuth } from 'redux/actions/auth';
import {
  BASE_URL,
  TOKEN_STORAGE_KEY,
  PRODUCT_TYPE,
  ACCOUNTS_DASHBOARD_URL,
  LANGUAGE_STORAGE_KEY,
  SIGNIN_ROUTE,
} from 'redux/actions/constants';
import { setHeader, clearHeader } from 'redux/actions/header';
import { setNotification } from 'redux/actions/notifications';
import { setToStorage, removeFromStorage } from 'redux/actions/storage';
import { startLocalization } from 'redux/actions/localization';
import { setLoader, setRedirect, setOrigin } from 'redux/actions/ui';
import { sendLog } from 'redux/actions/log';
import SeverityTypes from 'enums/severity-types';
import {
  SIGNIN_FEATURE,
  START_SIGNIN_PAGE,
  SIGNIN,
  VCLOUD_FEATURES,
  VALIDATE_VCLOUD_TOKEN,
  PUSULA_FEATURES,
  VALIDATE_PUSULA_STUDENT_KEY,
  VALIDATE_PUSULA_TEACHER_KEY,
  NAVBAR,
  SIGNOUT,
  CHANGE_LANGUAGE,
  CREATE_PASSWORD_PAGE,
  START_CREATE_PASSWORD_PAGE,
  VALIDATE_EMAIL_TOKEN,
  RESET_PASSWORD,
  FORGOT_PASSWORD_PAGE,
  START_FORGOT_PASSWORD_PAGE,
  SEND_LINK_BY_EMAIL,
  READ_BOOK,
  START_READ_BOOK_PAGE,
  SET_USER_BOOKMARK,
  REMOVE_USER_BOOKMARK,
  SAVE_USER_SOUND_URL,
  SET_BOOK_LAST_PAGE,
  CLOSE_CURRENT_BOOK,
  BOOK_LIST,
  START_BOOK_LIST_SCREEN,
  SEND_CONTACT_FORM,
  SEND_BUY_NOW_FORM,
  START_STUDENT_MESSAGES_PAGE,
  GET_STUDENT_MESSAGES,
  START_STUDENT_HOME_PAGE,
  CHECK_INCOMPLETE_ASSIGNMENT,
  START_STUDENT_BOOKS_PAGE,
  GET_STUDENT_BOOKS,
  START_STUDENT_ASSIGNMENTS_PAGE,
  GET_STUDENT_ASSIGNMENTS,
  MATERIAL_TASK_COMPLETED,
  GET_QUIZ_DETAIL,
  SEND_QUIZ_RESULT,
  GET_AUDIO_UPLOAD_PARAMS,
  REMOVE_AUDIO_UPLOAD_PARAMS,
  START_STUDENT_REPORTS_PAGE,
  GET_STUDENT_REPORTS,
  GET_STUDENT_TOTAL_POINT,
} from 'redux/actions/pages';
import {
  setData,
  removeData,
  updateWithPartOfItem,
  updateOneObjectArrayItem,
  removeOneObjectArrayItem,
  clearData,
  putData,
} from 'redux/actions/data';
import { setMessage } from 'redux/actions/message';
import MessageTypes from 'enums/message-types';
import UserTypes from 'enums/user-types';
import { backendErrorMapper } from 'mappers/backend-error.mapper';

/**
 * CONSTANTS
 */
// const HOMEPAGE_ROUTE = '/presentation';

// Signin Releated Constants
const SIGNIN_URL = `${BASE_URL}/api/user/signin`;

// Validate Vcloud Token Releated Constants
const VALIDATE_VCLOUD_TOKEN_URL = `${BASE_URL}/api/user/vc-signin`;

// Validate Pusula Token Releated Constants
const VALIDATE_PUSULA_USER_KEY_URL = `${BASE_URL}/api/user/pusula-signin`;

// Signout Releated Constants
const SIGNOUT_URL = `${BASE_URL}/api/user/signout`;

// Create Password Releated Constants
const CREATE_PASSWORD_PAGE_ROUTE = '/create-password';
const VALIDATE_EMAIL_TOKEN_URL = `${BASE_URL}/api/user/validate-email-token`;
const RESET_PASSWORD_URL = `${BASE_URL}/api/user/reset-password`;
const VALIDATE_EMAIL_TOKEN_ERROR_MSG = 'Email bağlantısı doğrulanamadı.';
const RESET_PASSWORD_SUCCESS_MSG =
  'Şifreniz başarılı bir şekilde değiştirildi.';
const RESET_PASSWORD_ERROR_MSG = 'Şifreniz değiştirilirken hata meydana geldi.';

// Forgot Password Releated Constants
const FORGOT_PASSWORD_PAGE_ROUTE = '/forgot-password';
const SEND_LINK_BY_EMAIL_URL = `${BASE_URL}/api/user/send-reset-password-email`;
const SEND_LINK_BY_EMAIL_SUCCESS_MSG =
  'Şifre değiştirme bağlantısı email olarak gönderilmiştir. Lütfen email kutunuzu kontrol ediniz ve işleme devam ediniz.';
const SEND_LINK_BY_EMAIL_ERROR_MSG =
  'Şifre değiştirme bağlantısı yollanırken bir hata oluştu.';

// Read Book Releated Contants
const READ_BOOK_ROUTE = '/read';
const GET_CURRENT_BOOK_DETAILED_ERROR_MSG =
  'Kitap alınırken hata meydana geldi.';
const SAVE_USER_SOUND_URL_URL = `${BASE_URL}/api/book/save-audio`;
const SAVE_USER_SOUND_URL_SUCCESS_MSG =
  'Ses kaydınız başarılı bir şekilde gönderildi.';
const SAVE_USER_SOUND_URL_ERROR_MSG =
  'Ses kaydınız gönderilirken hata meydana geldi. Lütfen tekrar deneyiniz.';
const SET_USER_BOOKMARK_URL = `${BASE_URL}/api/book/set-bookmark`;
const REMOVE_USER_BOOKMARK_URL = `${BASE_URL}/api/book/remove-bookmark`;
const SET_USER_BOOKMARK_SUCCESS_MSG =
  'Yer imi başarılı bir şekilde kaydedildi.';
const SET_USER_BOOKMARK_ERROR_MSG = 'Yer imi kaydedilirken hata meydana geldi.';
const REMOVE_USER_BOOKMARK_SUCCESS_MSG =
  'Yer imi başarılı bir şekilde kaldırıldı.';
const REMOVE_USER_BOOKMARK_ERROR_MSG = 'Yer imi silinirken hata meydana geldi.';
const SET_BOOK_LAST_PAGE_URL = `${BASE_URL}/api/book/set-book-last-page`;
// const SET_BOOK_LAST_PAGE_ERROR_MSG = 'Kitapta son kalınan sayfa kaydedilemedi.';

// Book List Releated Constants
const BOOK_LIST_ROUTE = '/book-list';
const GET_BOOK_LIST_URL = `${BASE_URL}/api/book/extended-only-cover`;
const GET_BOOK_LIST_API_ERROR_MSG =
  'Kitap listesi alınırken hata meydana geldi.';

// Contact Us Releated Constants
const SEND_CONTACT_FORM_URL = `${BASE_URL}/api/email/contact-us`;
const SEND_CONTACT_FORM_ERROR_MSG =
  'Mesajınız iletilirken hata meydana geldi. Lütfen tekrar deneyiniz.';
const SEND_CONTACT_FORM_SUCCESS_MSG =
  'Mesajınız başarılı bir şekilde iletilmiştir. En kısa sürede sizinle iletişime geçeceğiz.';

// Buy Now Releated Constants
const SEND_BUY_NOW_FORM_URL = `${BASE_URL}/api/email/buy-now`;
const SEND_BUY_NOW_FORM_ERROR_MSG =
  'Mesajınız iletilirken hata meydana geldi. Lütfen tekrar deneyiniz.';
const SEND_BUY_NOW_FORM_SUCCESS_MSG =
  'Mesajınız başarılı bir şekilde iletilmiştir. En kısa sürede sizinle iletişime geçeceğiz.';

// Student Home/Messages Pages Related Constants
const STUDENT_HOME_ROUTE = '/student';
const STUDENT_MESSAGES_ROUTE = '/messages';
const GET_STUDENT_MESSAGES_URL = `${BASE_URL}/api/book/student/get-messages`;
const GET_STUDENT_MESSAGES_ERROR_MSG =
  'Mesajlar listelenirken hata meydana geldi.';
const CHECK_INCOMPLETE_ASSIGNMENT_URL = `${BASE_URL}/api/book/student/has-incomplete-assignment`;
// const CHECK_INCOMPLETE_ASSIGNMENT_ERROR_MSG =
//   'Tamamlanmış görev varlığı tespit edilemedi.';
const GET_STUDENT_TOTAL_POINT_URL = `${BASE_URL}/api/book/get-total-point`;
const GET_STUDENT_TOTAL_POINT_ERROR_MSG =
  'Öğrenci toplam puanı alınırken hata meydana geldi.';

// Student Books Page Releated Constants
const STUDENT_BOOKS_ROUTE = '/books';
const GET_STUDENT_BOOKS_URL = `${BASE_URL}/api/book/student/all-books`;
const GET_STUDENT_BOOKS_ERROR_MSG =
  'Kitap listesi alınırken hata meydana geldi.';

// Student Assignments Page Releated Constants
const STUDENT_ASSIGNMENTS_ROUTE = '/assignments';
const GET_STUDENT_ASSIGNMENTS_URL = `${BASE_URL}/api/book/student/assigned-books`;
const GET_STUDENT_ASSIGNMENTS_ERROR_MSG =
  'Görev listesi alınırken hata meydana geldi.';
const MATERIAL_TASK_COMPLETED_URL = `${BASE_URL}/api/book/set-task-completed`;
const MATERIAL_TASK_COMPLETED_ERROR_MSG =
  'Materyal görevi tamamlama işlemi başarısız oldu.';

// Quiz Modal Releated Constants
const GET_QUIZ_DETAIL_URL = `${BASE_URL}/api/book/quiz/quiz-detail`;
const GET_QUIZ_DETAIL_ERROR_MSG = 'Değerlendirme alınırken hata meydana geldi.';
const SEND_QUIZ_RESULT_URL = `${BASE_URL}/api/book/set-quiz-result`;
const SEND_QUIZ_RESULT_ERROR_MSG =
  'Değerlendirme sonuçları iletilirken hata meydana geldi.';

// Digital Ocean Presigned Url releated Constants
const GET_AUDIO_UPLOAD_PARAMS_URL = `${BASE_URL}/api/upload/get-do-presigned-url`;
const GET_AUDIO_UPLOAD_PARAMS_SUCCESS_MSG = "Upload link'i başarıyla alındı.";
const GET_AUDIO_UPLOAD_PARAMS_ERROR_MSG =
  "Upload link'i alınırken hata meydana geldi.";

// Student Report releated Constants
const STUDENT_REPORTS_ROUTE = '/reports';
const GET_STUDENT_REPORTS_URL = `${BASE_URL}/api/book/get-user-report`;
const GET_STUDENT_REPORTS_ERROR_MSG =
  'Öğrenci raporu getirilirken hata meydana geldi.';

export const pagesMiddleware =
  ({ getState, dispatch }) =>
  (next) =>
  (action) => {
    next(action);

    const { ui, auth, data, localization } = getState();
    const language = localization?.language ?? 'TR';
    const localizations = localization?.pairs;

    switch (action.type) {
      // SIGNIN PAGE
      case START_SIGNIN_PAGE:
        if (ui && ui.redirectTo && ui.redirectTo === SIGNIN_ROUTE) {
          next(setRedirect({ to: '', feature: SIGNIN_FEATURE }));
        }

        const { currentUser } = auth;
        if (currentUser && currentUser.id && currentUser.id > 0) {
          if (ui.origin && ui.origin !== '') {
            next(setRedirect({ to: ui.origin, feature: SIGNIN_FEATURE }));
            next(setOrigin({ origin: '', feature: SIGNIN_FEATURE }));
          } else {
            next(setRedirect({ to: '/', feature: SIGNIN_FEATURE }));
          }
        }
        break;
      case SIGNIN:
        next(setLoader({ state: true, feature: SIGNIN_FEATURE }));
        next(
          apiRequest({
            body: action.payload,
            method: 'POST',
            url: SIGNIN_URL,
            feature: SIGNIN_FEATURE,
          })
        );
        break;
      case `${SIGNIN_FEATURE} ${API_SUCCESS}`:
        next(setLoader({ state: false, feature: SIGNIN_FEATURE }));
        next(setAuth({ auth: action.payload }));
        next(
          setHeader({
            header: {
              organization: action.payload.currentUser?.organization?.id,
              suborganization:
                action.payload.currentUser?.suborganizations[0]?.id,
            },
          })
        );
        next(
          setToStorage({
            storageKey: TOKEN_STORAGE_KEY,
            storageValue: action.payload.token,
            feature: SIGNIN_FEATURE,
          })
        );

        const userType = action.payload.currentUser?.userType;
        if (userType === UserTypes.other || userType === UserTypes.demoOther) {
          // window.location.href = ACCOUNTS_DASHBOARD_URL;
          window.open(
            `${ACCOUNTS_DASHBOARD_URL}?op=${action.payload.token}`,
            '_self'
          );
        } else if (
          userType === UserTypes.student ||
          userType === UserTypes.demoStudent
        ) {
          next(setRedirect({ to: '/student', feature: SIGNIN_FEATURE }));
        } else {
          next(setRedirect({ to: '/', feature: SIGNIN_FEATURE }));
        }
        break;
      case `${SIGNIN_FEATURE} ${API_ERROR}`:
        next(setLoader({ state: false, feature: SIGNIN_FEATURE }));
        if (action.payload.errors) {
          action.payload.errors.forEach((error) => {
            next(
              setNotification({
                type: MessageTypes.error,
                message: backendErrorMapper[language][error.message],
                feature: SIGNIN_FEATURE,
              })
            );
          });
        } else if (action.payload.status === 'fail' && action.payload.data) {
          Object.values(action.payload.data).forEach((err) => {
            next(
              setNotification({
                type: MessageTypes.error,
                message: backendErrorMapper[language][err],
                feature: SIGNIN_FEATURE,
              })
            );
          });
        } else {
          next(
            setNotification({
              type: MessageTypes.error,
              message: backendErrorMapper[language][action.payload.message],
              feature: SIGNIN_FEATURE,
            })
          );
        }
        next(
          removeFromStorage({
            storageKey: TOKEN_STORAGE_KEY,
            feature: SIGNIN_FEATURE,
          })
        );
        next(
          sendLog({
            product: PRODUCT_TYPE,
            title: `${SIGNIN_FEATURE} ${API_ERROR}`,
            detail: action.payload,
            severity: SeverityTypes.major,
          })
        );
        break;
      case VALIDATE_VCLOUD_TOKEN:
        next(
          apiRequest({
            body: action.payload,
            method: 'POST',
            url: VALIDATE_VCLOUD_TOKEN_URL,
            feature: VCLOUD_FEATURES,
          })
        );
        break;
      case `${VCLOUD_FEATURES} ${API_SUCCESS}`:
        next(setAuth({ auth: action.payload }));

        // Setheader içerisinde current suborganization ve organization bilgileri set edilecek.
        next(
          setHeader({
            header: {
              organization: action.payload.currentUser?.organization?.id,
              suborganization: action.payload.currentSubOrganizationId,
            },
          })
        );
        next(
          setToStorage({
            storageKey: TOKEN_STORAGE_KEY,
            storageValue: action.payload.token,
            feature: VCLOUD_FEATURES,
          })
        );

        const usrType = action.payload.currentUser?.userType;
        if (usrType === UserTypes.other || usrType === UserTypes.demoOther) {
          window.open(
            `${ACCOUNTS_DASHBOARD_URL}?op=${action.payload.token}`,
            '_self'
          );
        } else if (
          usrType === UserTypes.student ||
          usrType === UserTypes.demoStudent
        ) {
          const navAgent =
            navigator.userAgent || navigator.vendor || window.opera;

          if (
            navAgent.includes('Android') ||
            navAgent.includes('iPhone') ||
            navAgent.includes('iPad') ||
            (navAgent.includes('Macintosh') && navigator.maxTouchPoints > 1)
          ) {
            next(
              setMessage({
                messageType: MessageTypes.success,
                messageContent: {
                  token: action.payload.token,
                  isIOS:
                    navAgent.includes('iPhone') ||
                    navAgent.includes('iPad') ||
                    (navAgent.includes('Macintosh') &&
                      navigator.maxTouchPoints > 1),
                },
                feature: VCLOUD_FEATURES,
              })
            );
          } else {
            next(setRedirect({ to: '/student', feature: VCLOUD_FEATURES }));
          }
        } else {
          next(setRedirect({ to: '/', feature: VCLOUD_FEATURES }));
        }
        break;

      case `${VCLOUD_FEATURES} ${API_ERROR}`:
        next(setAuth({ auth: {} }));
        next(clearData());
        next(clearHeader());
        next(
          removeFromStorage({
            storageKey: TOKEN_STORAGE_KEY,
            feature: VCLOUD_FEATURES,
          })
        );
        next(setRedirect({ to: SIGNIN_ROUTE, feature: NAVBAR }));

        // TODO: Bu kısımda hata mesajı görülecek!
        // if (action.payload.errors) {
        //   action.payload.errors.forEach((error) => {
        //     next(
        //       setNotification({
        //         type: MessageTypes.error,
        //         message: backendErrorMapper[language][error.message],
        //         feature: VCLOUD_FEATURES,
        //       })
        //     );
        //   });
        // } else if (action.payload.status === 'fail' && action.payload.data) {
        //   Object.values(action.payload.data).forEach((err) => {
        //     next(
        //       setNotification({
        //         type: MessageTypes.error,
        //         message: backendErrorMapper[language][err],
        //         feature: VCLOUD_FEATURES,
        //       })
        //     );
        //   });
        // } else {
        //   next(
        //     setNotification({
        //       type: MessageTypes.error,
        //       message: backendErrorMapper[language][action.payload.message],
        //       feature: VCLOUD_FEATURES,
        //     })
        //   );
        // }
        // next(
        //   removeFromStorage({
        //     storageKey: TOKEN_STORAGE_KEY,
        //     feature: VCLOUD_FEATURES,
        //   })
        // );
        // next(
        //   sendLog({
        //     product: PRODUCT_TYPE,
        //     title: `${VCLOUD_FEATURES} ${API_ERROR}`,
        //     detail: action.payload,
        //     severity: SeverityTypes.major,
        //   })
        // );
        break;

      case VALIDATE_PUSULA_STUDENT_KEY:
      case VALIDATE_PUSULA_TEACHER_KEY:
        next(
          apiRequest({
            body: action.payload,
            method: 'POST',
            url: VALIDATE_PUSULA_USER_KEY_URL,
            feature: PUSULA_FEATURES,
          })
        );
        break;
      case `${PUSULA_FEATURES} ${API_SUCCESS}`:
        next(setAuth({ auth: action.payload }));

        // Setheader içerisinde current suborganization ve organization bilgileri set edilecek.
        next(
          setHeader({
            header: {
              organization: action.payload.currentUser?.organization?.id,
              suborganization: action.payload.currentSubOrganizationId,
            },
          })
        );
        next(
          setToStorage({
            storageKey: TOKEN_STORAGE_KEY,
            storageValue: action.payload.token,
            feature: PUSULA_FEATURES,
          })
        );

        const currentUserType = action.payload.currentUser?.userType;
        if (
          currentUserType === UserTypes.other ||
          currentUserType === UserTypes.demoOther
        ) {
          window.open(
            `${ACCOUNTS_DASHBOARD_URL}?op=${action.payload.token}`,
            '_self'
          );
        } else if (
          currentUserType === UserTypes.student ||
          currentUserType === UserTypes.demoStudent
        ) {
          const navAgent =
            navigator.userAgent || navigator.vendor || window.opera;

          if (
            navAgent.includes('Android') ||
            navAgent.includes('iPhone') ||
            navAgent.includes('iPad') ||
            (navAgent.includes('Macintosh') && navigator.maxTouchPoints > 1)
          ) {
            next(
              setMessage({
                messageType: MessageTypes.success,
                messageContent: {
                  token: action.payload.token,
                  isIOS:
                    navAgent.includes('iPhone') ||
                    navAgent.includes('iPad') ||
                    (navAgent.includes('Macintosh') &&
                      navigator.maxTouchPoints > 1),
                },
                feature: PUSULA_FEATURES,
              })
            );
          } else {
            next(setRedirect({ to: '/student', feature: PUSULA_FEATURES }));
          }
        } else {
          next(setRedirect({ to: '/', feature: PUSULA_FEATURES }));
        }
        break;

      case `${PUSULA_FEATURES} ${API_ERROR}`:
        next(setAuth({ auth: {} }));
        next(clearData());
        next(clearHeader());
        next(
          removeFromStorage({
            storageKey: TOKEN_STORAGE_KEY,
            feature: PUSULA_FEATURES,
          })
        );
        next(setRedirect({ to: SIGNIN_ROUTE, feature: NAVBAR }));

        // TODO: Bu kısımda hata mesajı görülecek!
        // if (action.payload.errors) {
        //   action.payload.errors.forEach((error) => {
        //     next(
        //       setNotification({
        //         type: MessageTypes.error,
        //         message: backendErrorMapper[language][error.message],
        //         feature: PUSULA_FEATURES,
        //       })
        //     );
        //   });
        // } else if (action.payload.status === 'fail' && action.payload.data) {
        //   Object.values(action.payload.data).forEach((err) => {
        //     next(
        //       setNotification({
        //         type: MessageTypes.error,
        //         message: backendErrorMapper[language][err],
        //         feature: PUSULA_FEATURES,
        //       })
        //     );
        //   });
        // } else {
        //   next(
        //     setNotification({
        //       type: MessageTypes.error,
        //       message: backendErrorMapper[language][action.payload.message],
        //       feature: PUSULA_FEATURES,
        //     })
        //   );
        // }
        // next(
        //   removeFromStorage({
        //     storageKey: TOKEN_STORAGE_KEY,
        //     feature: PUSULA_FEATURES,
        //   })
        // );
        // next(
        //   sendLog({
        //     product: PRODUCT_TYPE,
        //     title: `${PUSULA_FEATURES} ${API_ERROR}`,
        //     detail: action.payload,
        //     severity: SeverityTypes.major,
        //   })
        // );
        break;

      case SIGNOUT:
        next(setLoader({ state: true, feature: NAVBAR }));
        next(
          apiRequest({
            body: action.payload,
            method: 'POST',
            url: SIGNOUT_URL,
            feature: NAVBAR,
          })
        );
        break;
      case `${NAVBAR} ${API_SUCCESS}`:
        next(setLoader({ state: false, feature: NAVBAR }));
        next(setAuth({ auth: action.payload }));
        next(clearData());
        next(clearHeader());
        next(
          removeFromStorage({
            storageKey: TOKEN_STORAGE_KEY,
            feature: NAVBAR,
          })
        );
        next(setRedirect({ to: SIGNIN_ROUTE, feature: NAVBAR }));
        break;
      case `${NAVBAR} ${API_ERROR}`:
        next(setLoader({ state: false, feature: NAVBAR }));
        next(setAuth({ auth: {} }));
        next(
          removeFromStorage({
            storageKey: TOKEN_STORAGE_KEY,
            feature: NAVBAR,
          })
        );
        next(setRedirect({ to: SIGNIN_ROUTE, feature: NAVBAR }));
        next(
          sendLog({
            product: PRODUCT_TYPE,
            title: `${NAVBAR} ${API_ERROR}`,
            detail: action.payload,
            severity: SeverityTypes.major,
          })
        );
        break;
      case CHANGE_LANGUAGE:
        next(
          setToStorage({
            storageKey: LANGUAGE_STORAGE_KEY,
            storageValue: action.payload.language,
            feature: CHANGE_LANGUAGE,
          })
        );
        dispatch(startLocalization(action.payload.language));
        break;
      case START_CREATE_PASSWORD_PAGE:
        if (
          ui &&
          ui.redirectTo &&
          ui.redirectTo === CREATE_PASSWORD_PAGE_ROUTE
        ) {
          next(
            setRedirect({
              to: '',
              feature: CREATE_PASSWORD_PAGE,
            })
          );
        }
        break;
      case VALIDATE_EMAIL_TOKEN:
        next(setLoader({ state: true, feature: VALIDATE_EMAIL_TOKEN }));
        next(
          apiRequest({
            body: action.payload,
            method: 'POST',
            url: VALIDATE_EMAIL_TOKEN_URL,
            feature: VALIDATE_EMAIL_TOKEN,
          })
        );
        break;
      case `${VALIDATE_EMAIL_TOKEN} ${API_SUCCESS}`:
        next(setLoader({ state: false, feature: VALIDATE_EMAIL_TOKEN }));
        break;
      case `${VALIDATE_EMAIL_TOKEN} ${API_ERROR}`:
        next(setLoader({ state: false, feature: VALIDATE_EMAIL_TOKEN }));
        next(
          setMessage({
            messageType: MessageTypes.error,
            messageContent:
              localizations?.VALIDATE_EMAIL_TOKEN_ERROR_MSG ??
              VALIDATE_EMAIL_TOKEN_ERROR_MSG,
            feature: VALIDATE_EMAIL_TOKEN,
          })
        );
        next(
          sendLog({
            product: PRODUCT_TYPE,
            title: `${VALIDATE_EMAIL_TOKEN} ${API_ERROR}`,
            detail: action.payload,
            severity: SeverityTypes.major,
          })
        );
        break;
      case RESET_PASSWORD:
        next(setLoader({ state: true, feature: RESET_PASSWORD }));
        next(
          apiRequest({
            body: action.payload,
            method: 'POST',
            url: RESET_PASSWORD_URL,
            feature: RESET_PASSWORD,
          })
        );
        break;
      case `${RESET_PASSWORD} ${API_SUCCESS}`:
        next(setLoader({ state: false, feature: RESET_PASSWORD }));
        next(
          setNotification({
            type: MessageTypes.success,
            message:
              localizations?.RESET_PASSWORD_SUCCESS_MSG ??
              RESET_PASSWORD_SUCCESS_MSG,
            feature: RESET_PASSWORD,
          })
        );
        next(setRedirect({ to: SIGNIN_ROUTE, feature: RESET_PASSWORD }));
        break;
      case `${RESET_PASSWORD} ${API_ERROR}`:
        next(setLoader({ state: false, feature: RESET_PASSWORD }));
        next(
          setNotification({
            type: MessageTypes.error,
            message:
              localizations?.RESET_PASSWORD_ERROR_MSG ??
              RESET_PASSWORD_ERROR_MSG,
            feature: RESET_PASSWORD,
          })
        );
        if (action.payload.errors) {
          action.payload.errors.forEach((error) => {
            next(
              setNotification({
                type: MessageTypes.error,
                message: backendErrorMapper[language][error.message],
                feature: RESET_PASSWORD,
              })
            );
          });
        } else if (action.payload.status === 'fail' && action.payload.data) {
          Object.values(action.payload.data).forEach((err) => {
            next(
              setNotification({
                type: MessageTypes.error,
                message: backendErrorMapper[language][err],
                feature: RESET_PASSWORD,
              })
            );
          });
        } else {
          next(
            setNotification({
              type: MessageTypes.error,
              message: backendErrorMapper[language][action.payload.message],
              feature: RESET_PASSWORD,
            })
          );
        }
        next(
          sendLog({
            product: PRODUCT_TYPE,
            title: `${RESET_PASSWORD} ${API_ERROR}`,
            detail: action.payload,
            severity: SeverityTypes.major,
          })
        );
        break;
      case START_FORGOT_PASSWORD_PAGE:
        if (
          ui &&
          ui.redirectTo &&
          ui.redirectTo === FORGOT_PASSWORD_PAGE_ROUTE
        ) {
          next(
            setRedirect({
              to: '',
              feature: FORGOT_PASSWORD_PAGE,
            })
          );
        }
        break;
      case SEND_LINK_BY_EMAIL:
        next(setLoader({ state: true, feature: SEND_LINK_BY_EMAIL }));
        next(
          apiRequest({
            body: action.payload,
            method: 'POST',
            url: SEND_LINK_BY_EMAIL_URL,
            feature: SEND_LINK_BY_EMAIL,
          })
        );
        break;
      case `${SEND_LINK_BY_EMAIL} ${API_SUCCESS}`:
        next(setLoader({ state: false, feature: SEND_LINK_BY_EMAIL }));
        next(
          setNotification({
            type: MessageTypes.success,
            message:
              localizations?.SEND_LINK_BY_EMAIL_SUCCESS_MSG ??
              SEND_LINK_BY_EMAIL_SUCCESS_MSG,
            feature: SEND_LINK_BY_EMAIL,
          })
        );
        break;
      case `${SEND_LINK_BY_EMAIL} ${API_ERROR}`:
        next(setLoader({ state: false, feature: SEND_LINK_BY_EMAIL }));
        next(
          setNotification({
            type: MessageTypes.error,
            message:
              localizations?.SEND_LINK_BY_EMAIL_ERROR_MSG ??
              SEND_LINK_BY_EMAIL_ERROR_MSG,
            feature: SEND_LINK_BY_EMAIL,
          })
        );
        if (action.payload.errors) {
          action.payload.errors.forEach((error) => {
            next(
              setNotification({
                type: MessageTypes.error,
                message: backendErrorMapper[language][error.message],
                feature: SEND_LINK_BY_EMAIL,
              })
            );
          });
        } else if (action.payload.status === 'fail' && action.payload.data) {
          Object.values(action.payload.data).forEach((err) => {
            next(
              setNotification({
                type: MessageTypes.error,
                message: backendErrorMapper[language][err],
                feature: SEND_LINK_BY_EMAIL,
              })
            );
          });
        } else {
          next(
            setNotification({
              type: MessageTypes.error,
              message: backendErrorMapper[language][action.payload.message],
              feature: SEND_LINK_BY_EMAIL,
            })
          );
        }
        next(
          sendLog({
            product: PRODUCT_TYPE,
            title: `${SEND_LINK_BY_EMAIL} ${API_ERROR}`,
            detail: action.payload,
            severity: SeverityTypes.major,
          })
        );
        break;
      case START_READ_BOOK_PAGE:
        if (ui && ui.redirectTo && ui.redirectTo.includes(READ_BOOK_ROUTE)) {
          next(setRedirect({ to: '', feature: READ_BOOK }));
        }

        if (data && data.currentBook) {
          // DO NOTHING
        } else {
          next(setLoader({ state: true, feature: READ_BOOK }));
          next(
            apiRequest({
              method: 'GET',
              url: `${BASE_URL}/api/book/${action.payload.bookId}/extended-with-user-data`,
              feature: READ_BOOK,
            })
          );
        }
        break;
      case `${READ_BOOK} ${API_SUCCESS}`:
        next(setLoader({ state: false, feature: READ_BOOK }));
        next(setData({ data: action.payload, feature: READ_BOOK }));
        break;
      case `${READ_BOOK} ${API_ERROR}`:
        next(setLoader({ state: false, feature: READ_BOOK }));
        next(
          setMessage({
            messageType: MessageTypes.error,
            messageContent:
              localizations?.GET_CURRENT_BOOK_DETAILED_ERROR_MSG ??
              GET_CURRENT_BOOK_DETAILED_ERROR_MSG,
            feature: READ_BOOK,
          })
        );
        next(
          sendLog({
            product: PRODUCT_TYPE,
            title: `${READ_BOOK} ${API_ERROR}`,
            detail: action.payload,
            severity: SeverityTypes.critical,
          })
        );
        break;
      case SET_USER_BOOKMARK:
        next(
          apiRequest({
            body: { bookmark: action.payload },
            method: 'POST',
            url: SET_USER_BOOKMARK_URL,
            feature: SET_USER_BOOKMARK,
          })
        );
        break;
      case `${SET_USER_BOOKMARK} ${API_SUCCESS}`:
        next(
          updateOneObjectArrayItem({
            feature: SET_USER_BOOKMARK,
            where: 'book',
            key: 'userBookmarks',
            id: action.payload.bookmark.id,
            data: action.payload.bookmark,
          })
        );
        next(
          setNotification({
            type: MessageTypes.success,
            message:
              localizations?.SET_USER_BOOKMARK_SUCCESS_MSG ??
              SET_USER_BOOKMARK_SUCCESS_MSG,
            feature: SET_USER_BOOKMARK,
          })
        );
        break;
      case `${SET_USER_BOOKMARK} ${API_ERROR}`:
        next(
          setNotification({
            type: MessageTypes.error,
            message:
              localizations?.SET_USER_BOOKMARK_ERROR_MSG ??
              SET_USER_BOOKMARK_ERROR_MSG,
            feature: SET_USER_BOOKMARK,
          })
        );
        next(
          sendLog({
            product: PRODUCT_TYPE,
            title: `${SET_USER_BOOKMARK} ${API_ERROR}`,
            detail: action.payload,
            severity: SeverityTypes.major,
          })
        );
        break;

      case REMOVE_USER_BOOKMARK:
        next(
          apiRequest({
            body: { bookmark: action.payload },
            method: 'POST',
            url: REMOVE_USER_BOOKMARK_URL,
            feature: REMOVE_USER_BOOKMARK,
          })
        );
        break;
      case `${REMOVE_USER_BOOKMARK} ${API_SUCCESS}`:
        next(
          removeOneObjectArrayItem({
            feature: REMOVE_USER_BOOKMARK,
            where: 'book',
            key: 'userBookmarks',
            id: action.payload.bookmark.id,
          })
        );
        next(
          setNotification({
            type: MessageTypes.success,
            message:
              localizations?.REMOVE_USER_BOOKMARK_SUCCESS_MSG ??
              REMOVE_USER_BOOKMARK_SUCCESS_MSG,
            feature: REMOVE_USER_BOOKMARK,
          })
        );
        break;
      case `${REMOVE_USER_BOOKMARK} ${API_ERROR}`:
        next(
          setNotification({
            type: MessageTypes.error,
            message:
              localizations?.REMOVE_USER_BOOKMARK_ERROR_MSG ??
              REMOVE_USER_BOOKMARK_ERROR_MSG,
            feature: REMOVE_USER_BOOKMARK,
          })
        );
        next(
          sendLog({
            product: PRODUCT_TYPE,
            title: `${REMOVE_USER_BOOKMARK} ${API_ERROR}`,
            detail: action.payload,
            severity: SeverityTypes.major,
          })
        );
        break;

      case SAVE_USER_SOUND_URL:
        //next(setLoader({ state: true, feature: READ_BOOK }));
        next(
          apiRequest({
            body: { audio: action.payload },
            method: 'POST',
            url: SAVE_USER_SOUND_URL_URL,
            feature: SAVE_USER_SOUND_URL,
          })
        );
        break;
      case `${SAVE_USER_SOUND_URL} ${API_SUCCESS}`:
        const { audio } = action.payload;
        if (audio?.bookId > 0) {
          if (data.myAssignedBooks?.books?.length > 0) {
            const copyOfMyAssignedBooksForRecording = _.cloneDeepWith(
              data.myAssignedBooks
            );

            data.myAssignedBooks.books.forEach((book, bookIndex) => {
              if (book.id === audio.bookId) {
                _.set(
                  copyOfMyAssignedBooksForRecording,
                  ['books', bookIndex, 'audioRecorded'],
                  true
                );

                book.tasks.forEach((task, taskIndex) => {
                  if (task.taskType === 'RECORD') {
                    task.elements.forEach((element, elementIndex) => {
                      if (!element.isCompleted) {
                        _.set(
                          copyOfMyAssignedBooksForRecording,
                          [
                            'books',
                            bookIndex,
                            'tasks',
                            taskIndex,
                            'elements',
                            elementIndex,
                            'isCompleted',
                          ],
                          true
                        );
                      }
                    });
                  }
                });
              }
            });

            next(
              setData({
                data: {
                  myAssignedBooks: copyOfMyAssignedBooksForRecording,
                },
                feature: SAVE_USER_SOUND_URL,
              })
            );
          }

          if (data.myBooks?.books?.length > 0) {
            const copyOfMyBooksForRecording = _.cloneDeepWith(data.myBooks);

            data.myBooks.books.forEach((book, bookIndex) => {
              if (book.id === audio.bookId) {
                if (!book.audioRecorded) {
                  _.set(
                    copyOfMyBooksForRecording,
                    ['books', bookIndex, 'audioRecorded'],
                    true
                  );
                }
              }
            });

            next(
              setData({
                data: {
                  myBooks: copyOfMyBooksForRecording,
                },
                feature: SAVE_USER_SOUND_URL,
              })
            );
          }
        }

        next(
          setNotification({
            type: MessageTypes.success,
            message:
              localizations?.SAVE_USER_SOUND_URL_SUCCESS_MSG ??
              SAVE_USER_SOUND_URL_SUCCESS_MSG,
            feature: SAVE_USER_SOUND_URL,
          })
        );
        break;
      case `${SAVE_USER_SOUND_URL} ${API_ERROR}`:
        //next(setLoader({ state: false, feature: READ_BOOK }));
        // next(
        //   setMessage({
        //     messageType: MessageTypes.error,
        //     messageContent: SAVE_USER_SOUND_URL_ERROR_MSG,
        //     feature: SAVE_USER_SOUND_URL,
        //   })
        // );
        next(
          setNotification({
            type: MessageTypes.error,
            message:
              localizations?.SAVE_USER_SOUND_URL_ERROR_MSG ??
              SAVE_USER_SOUND_URL_ERROR_MSG,
            feature: SAVE_USER_SOUND_URL,
          })
        );
        next(
          sendLog({
            product: PRODUCT_TYPE,
            title: `${SAVE_USER_SOUND_URL} ${API_ERROR}`,
            detail: action.payload,
            severity: SeverityTypes.major,
          })
        );
        break;
      case SET_BOOK_LAST_PAGE:
        next(
          apiRequest({
            body: { bookLastPage: action.payload },
            method: 'POST',
            url: SET_BOOK_LAST_PAGE_URL,
            feature: SET_BOOK_LAST_PAGE,
          })
        );
        break;
      case `${SET_BOOK_LAST_PAGE} ${API_SUCCESS}`:
        if (data.book?.id === action.payload.bookLastPage?.bookId) {
          const {
            bookId,
            pageLocation,
            isBookReaded,
            listenPageLocation,
            isBookListened,
          } = action.payload.bookLastPage;

          next(
            putData({
              feature: SET_BOOK_LAST_PAGE,
              where: 'book',
              data: {
                resumePageLocation: pageLocation,
                resumeListenPageLocation: listenPageLocation,
                isBookReaded,
                isBookListened,
              },
            })
          );

          // TODO: Bu kısım sadece books varsa çalışmalı.
          next(
            updateWithPartOfItem({
              feature: SET_BOOK_LAST_PAGE,
              where: 'books',
              item: bookId,
              partOfItem: {
                pageLocation,
                isBookReaded,
                listenPageLocation,
                isBookListened,
              },
            })
          );

          // Eğer state.data.myAssignedBooks varsa
          // action.payload.bookLastPage'deki isBookReaded ya da isBookListened true ise
          if (
            data.myAssignedBooks?.books?.length > 0 &&
            (isBookListened || isBookReaded)
          ) {
            const copyOfMyAssignedBooksForReadingOrListening = _.cloneDeepWith(
              data.myAssignedBooks
            );

            data.myAssignedBooks.books.forEach((book, bookIndex) => {
              if (book.id === bookId) {
                if (isBookListened && !book.isBookListened) {
                  _.set(
                    copyOfMyAssignedBooksForReadingOrListening,
                    ['books', bookIndex, 'isBookListened'],
                    true
                  );
                }
                if (isBookReaded && !book.isBookReaded) {
                  _.set(
                    copyOfMyAssignedBooksForReadingOrListening,
                    ['books', bookIndex, 'isBookListened'],
                    true
                  );
                }

                book.tasks.forEach((task, taskIndex) => {
                  if (task.taskType === 'LISTEN' && isBookListened) {
                    task.elements.forEach((element, elementIndex) => {
                      if (!element.isCompleted) {
                        _.set(
                          copyOfMyAssignedBooksForReadingOrListening,
                          [
                            'books',
                            bookIndex,
                            'tasks',
                            taskIndex,
                            'elements',
                            elementIndex,
                            'isCompleted',
                          ],
                          true
                        );
                      }
                    });
                  }

                  if (task.taskType === 'READ' && isBookReaded) {
                    task.elements.forEach((element, elementIndex) => {
                      if (!element.isCompleted) {
                        _.set(
                          copyOfMyAssignedBooksForReadingOrListening,
                          [
                            'books',
                            bookIndex,
                            'tasks',
                            taskIndex,
                            'elements',
                            elementIndex,
                            'isCompleted',
                          ],
                          true
                        );
                      }
                    });
                  }
                });
              }
            });

            next(
              setData({
                data: {
                  myAssignedBooks: copyOfMyAssignedBooksForReadingOrListening,
                },
                feature: SET_BOOK_LAST_PAGE,
              })
            );
          }

          if (
            data.myBooks?.books?.length > 0 &&
            (isBookListened || isBookReaded)
          ) {
            const copyOfMyBooksForReadingOrListening = _.cloneDeepWith(
              data.myBooks
            );

            data.myBooks.books.forEach((book, bookIndex) => {
              if (book.id === bookId) {
                if (isBookListened && !book.isBookListened) {
                  _.set(
                    copyOfMyBooksForReadingOrListening,
                    ['books', bookIndex, 'isBookListened'],
                    true
                  );
                }
                if (isBookReaded && !book.isBookReaded) {
                  _.set(
                    copyOfMyBooksForReadingOrListening,
                    ['books', bookIndex, 'isBookReaded'],
                    true
                  );
                }
              }
            });

            next(
              setData({
                data: {
                  myBooks: copyOfMyBooksForReadingOrListening,
                },
                feature: SET_BOOK_LAST_PAGE,
              })
            );
          }
        }
        break;
      case `${SET_BOOK_LAST_PAGE} ${API_ERROR}`:
        next(
          sendLog({
            product: PRODUCT_TYPE,
            title: `${SET_BOOK_LAST_PAGE} ${API_ERROR}`,
            detail: action.payload,
            severity: SeverityTypes.major,
          })
        );
        break;
      case CLOSE_CURRENT_BOOK:
        const { bookId, pageLocation, listenPageLocation } = action.payload;

        if (data.myAssignedBooks?.books?.length > 0) {
          const copyOfMyAssignedBooksForLocations = _.cloneDeepWith(
            data.myAssignedBooks
          );

          data.myAssignedBooks.books.forEach((book, bookIndex) => {
            if (book.id === bookId) {
              _.set(
                copyOfMyAssignedBooksForLocations,
                ['books', bookIndex, 'pageLocation'],
                pageLocation
              );

              _.set(
                copyOfMyAssignedBooksForLocations,
                ['books', bookIndex, 'listenPageLocation'],
                listenPageLocation
              );
            }
          });

          next(
            setData({
              data: {
                myAssignedBooks: copyOfMyAssignedBooksForLocations,
              },
              feature: CLOSE_CURRENT_BOOK,
            })
          );
        }

        if (data.myBooks?.books?.length > 0) {
          const copyOfMyBooksForLocations = _.cloneDeepWith(data.myBooks);

          data.myBooks.books.forEach((book, bookIndex) => {
            if (book.id === bookId) {
              _.set(
                copyOfMyBooksForLocations,
                ['books', bookIndex, 'pageLocation'],
                pageLocation
              );
              _.set(
                copyOfMyBooksForLocations,
                ['books', bookIndex, 'listenPageLocation'],
                listenPageLocation
              );
            }
          });

          next(
            setData({
              data: {
                myBooks: copyOfMyBooksForLocations,
              },
              feature: CLOSE_CURRENT_BOOK,
            })
          );
        }
        break;
      case START_BOOK_LIST_SCREEN:
        if (ui && ui.redirectTo && ui.redirectTo.includes(BOOK_LIST_ROUTE)) {
          next(setRedirect({ to: '', feature: BOOK_LIST }));
        }

        if (data && data.books && data.books.length > 0) {
          // DO NOTHING
        } else {
          next(setLoader({ state: true, feature: BOOK_LIST }));
          next(
            apiRequest({
              method: 'GET',
              url: GET_BOOK_LIST_URL,
              feature: BOOK_LIST,
            })
          );
        }
        break;
      case `${BOOK_LIST} ${API_SUCCESS}`:
        next(setLoader({ state: false, feature: BOOK_LIST }));
        next(setData({ data: action.payload, feature: BOOK_LIST }));
        break;
      case `${BOOK_LIST} ${API_ERROR}`:
        next(setLoader({ state: false, feature: BOOK_LIST }));
        next(
          setMessage({
            messageType: MessageTypes.error,
            messageContent:
              localizations?.GET_BOOK_LIST_ERROR_MSG ??
              GET_BOOK_LIST_API_ERROR_MSG,
            feature: BOOK_LIST,
          })
        );
        next(
          sendLog({
            product: PRODUCT_TYPE,
            title: `${BOOK_LIST} ${API_ERROR}`,
            detail: action.payload,
            severity: SeverityTypes.critical,
          })
        );
        break;
      case SEND_CONTACT_FORM:
        next(setLoader({ state: true, feature: SEND_CONTACT_FORM }));
        next(
          apiRequest({
            body: action.payload,
            method: 'POST',
            url: SEND_CONTACT_FORM_URL,
            feature: SEND_CONTACT_FORM,
          })
        );
        break;
      case `${SEND_CONTACT_FORM} ${API_SUCCESS}`:
        next(setLoader({ state: false, feature: SEND_CONTACT_FORM }));
        next(
          setMessage({
            messageType: MessageTypes.success,
            messageContent:
              localizations?.SEND_CONTACT_FORM_SUCCESS_MSG ??
              SEND_CONTACT_FORM_SUCCESS_MSG,
            feature: SEND_CONTACT_FORM,
          })
        );
        break;
      case `${SEND_CONTACT_FORM} ${API_ERROR}`:
        next(setLoader({ state: false, feature: SEND_CONTACT_FORM }));
        if (action.payload.status === 'fail' && action.payload.data) {
          Object.values(action.payload.data).forEach((err) => {
            next(
              setNotification({
                type: MessageTypes.error,
                message: backendErrorMapper[language][err],
                feature: SEND_CONTACT_FORM,
              })
            );
          });
        } else {
          next(
            setMessage({
              messageType: MessageTypes.error,
              messageContent:
                localizations?.SEND_CONTACT_FORM_ERROR_MSG ??
                SEND_CONTACT_FORM_ERROR_MSG,
              feature: SEND_CONTACT_FORM,
            })
          );
        }

        next(
          sendLog({
            product: PRODUCT_TYPE,
            title: `${SEND_CONTACT_FORM} ${API_ERROR}`,
            detail: action.payload,
            severity: SeverityTypes.minor,
          })
        );
        break;
      case SEND_BUY_NOW_FORM:
        next(setLoader({ state: true, feature: SEND_BUY_NOW_FORM }));
        next(
          apiRequest({
            body: action.payload,
            method: 'POST',
            url: SEND_BUY_NOW_FORM_URL,
            feature: SEND_BUY_NOW_FORM,
          })
        );
        break;
      case `${SEND_BUY_NOW_FORM} ${API_SUCCESS}`:
        next(setLoader({ state: false, feature: SEND_BUY_NOW_FORM }));
        next(
          setMessage({
            messageType: MessageTypes.success,
            messageContent:
              localizations?.SEND_BUY_NOW_FORM_SUCCESS_MSG ??
              SEND_BUY_NOW_FORM_SUCCESS_MSG,
            feature: SEND_BUY_NOW_FORM,
          })
        );
        break;
      case `${SEND_BUY_NOW_FORM} ${API_ERROR}`:
        next(setLoader({ state: false, feature: SEND_BUY_NOW_FORM }));
        if (action.payload.status === 'fail' && action.payload.data) {
          Object.values(action.payload.data).forEach((err) => {
            next(
              setNotification({
                type: MessageTypes.error,
                message: backendErrorMapper[language][err],
                feature: SEND_BUY_NOW_FORM,
              })
            );
          });
        } else {
          next(
            setMessage({
              messageType: MessageTypes.error,
              messageContent:
                localizations?.SEND_BUY_NOW_FORM_ERROR_MSG ??
                SEND_BUY_NOW_FORM_ERROR_MSG,
              feature: SEND_BUY_NOW_FORM,
            })
          );
        }

        next(
          sendLog({
            product: PRODUCT_TYPE,
            title: `${SEND_BUY_NOW_FORM} ${API_ERROR}`,
            detail: action.payload,
            severity: SeverityTypes.minor,
          })
        );
        break;
      case START_STUDENT_MESSAGES_PAGE:
        if (ui && ui.redirectTo && ui.redirectTo === STUDENT_MESSAGES_ROUTE) {
          next(
            setRedirect({
              to: '',
              feature: START_STUDENT_MESSAGES_PAGE,
            })
          );
        }
        break;
      case GET_STUDENT_MESSAGES:
        next(setLoader({ state: true, feature: GET_STUDENT_MESSAGES }));
        next(
          apiRequest({
            body: action.payload,
            method: 'POST',
            url: GET_STUDENT_MESSAGES_URL,
            feature: GET_STUDENT_MESSAGES,
          })
        );
        break;
      case `${GET_STUDENT_MESSAGES} ${API_SUCCESS}`:
        next(setLoader({ state: false, feature: GET_STUDENT_MESSAGES }));
        next(
          setData({
            data: { myMessages: action.payload },
            feature: GET_STUDENT_MESSAGES,
          })
        );
        break;
      case `${GET_STUDENT_MESSAGES} ${API_ERROR}`:
        next(setLoader({ state: false, feature: GET_STUDENT_MESSAGES }));
        next(
          setNotification({
            type: MessageTypes.error,
            message:
              localizations?.GET_STUDENT_MESSAGES_ERROR_MSG ??
              GET_STUDENT_MESSAGES_ERROR_MSG,
            feature: GET_STUDENT_MESSAGES,
          })
        );
        next(
          sendLog({
            product: PRODUCT_TYPE,
            title: `${GET_STUDENT_MESSAGES} ${API_ERROR}`,
            detail: action.payload,
            severity: SeverityTypes.major,
          })
        );
        break;
      case START_STUDENT_HOME_PAGE:
        if (ui && ui.redirectTo && ui.redirectTo === STUDENT_HOME_ROUTE) {
          next(
            setRedirect({
              to: '',
              feature: START_STUDENT_HOME_PAGE,
            })
          );
        }
        break;
      case CHECK_INCOMPLETE_ASSIGNMENT:
        next(
          apiRequest({
            method: 'GET',
            url: CHECK_INCOMPLETE_ASSIGNMENT_URL,
            feature: CHECK_INCOMPLETE_ASSIGNMENT,
          })
        );
        break;
      case `${CHECK_INCOMPLETE_ASSIGNMENT} ${API_SUCCESS}`:
        next(
          setData({
            data: action.payload,
            feature: CHECK_INCOMPLETE_ASSIGNMENT,
          })
        );
        break;
      case `${CHECK_INCOMPLETE_ASSIGNMENT} ${API_ERROR}`:
        // next(
        //   setNotification({
        //     type: MessageTypes.error,
        //     message:
        //       localizations?.CHECK_INCOMPLETE_ASSIGNMENT_ERROR_MSG ??
        //       CHECK_INCOMPLETE_ASSIGNMENT_ERROR_MSG,
        //     feature: CHECK_INCOMPLETE_ASSIGNMENT,
        //   })
        // );
        next(
          sendLog({
            product: PRODUCT_TYPE,
            title: `${CHECK_INCOMPLETE_ASSIGNMENT} ${API_ERROR}`,
            detail: action.payload,
            severity: SeverityTypes.major,
          })
        );
        break;
      case START_STUDENT_BOOKS_PAGE:
        if (ui && ui.redirectTo && ui.redirectTo === STUDENT_BOOKS_ROUTE) {
          next(
            setRedirect({
              to: '',
              feature: START_STUDENT_BOOKS_PAGE,
            })
          );
        }
        break;
      case GET_STUDENT_BOOKS:
        if (data.myBooks?.books?.length > 0) {
          // Do nothing
        } else {
          next(setLoader({ state: true, feature: GET_STUDENT_BOOKS }));
          next(
            apiRequest({
              method: 'GET',
              url: GET_STUDENT_BOOKS_URL,
              feature: GET_STUDENT_BOOKS,
            })
          );
        }
        break;
      case `${GET_STUDENT_BOOKS} ${API_SUCCESS}`:
        next(setLoader({ state: false, feature: GET_STUDENT_BOOKS }));
        next(
          setData({
            data: { myBooks: action.payload },
            feature: GET_STUDENT_BOOKS,
          })
        );
        break;
      case `${GET_STUDENT_BOOKS} ${API_ERROR}`:
        next(setLoader({ state: false, feature: GET_STUDENT_BOOKS }));
        next(
          setMessage({
            messageType: MessageTypes.error,
            messageContent:
              localizations?.GET_STUDENT_BOOKS_ERROR_MSG ??
              GET_STUDENT_BOOKS_ERROR_MSG,
            feature: GET_STUDENT_BOOKS,
          })
        );
        break;
      case START_STUDENT_ASSIGNMENTS_PAGE:
        if (
          ui &&
          ui.redirectTo &&
          ui.redirectTo === STUDENT_ASSIGNMENTS_ROUTE
        ) {
          next(
            setRedirect({
              to: '',
              feature: START_STUDENT_ASSIGNMENTS_PAGE,
            })
          );
        }
        break;
      case GET_STUDENT_ASSIGNMENTS:
        if (data.myAssignedBooks?.books?.length > 0) {
          // Do nothing
        } else {
          next(setLoader({ state: true, feature: GET_STUDENT_ASSIGNMENTS }));
          next(
            apiRequest({
              method: 'GET',
              url: GET_STUDENT_ASSIGNMENTS_URL,
              feature: GET_STUDENT_ASSIGNMENTS,
            })
          );
        }
        break;
      case `${GET_STUDENT_ASSIGNMENTS} ${API_SUCCESS}`:
        next(setLoader({ state: false, feature: GET_STUDENT_ASSIGNMENTS }));
        next(
          setData({
            data: { myAssignedBooks: action.payload },
            feature: GET_STUDENT_ASSIGNMENTS,
          })
        );
        break;
      case `${GET_STUDENT_ASSIGNMENTS} ${API_ERROR}`:
        next(setLoader({ state: false, feature: GET_STUDENT_ASSIGNMENTS }));
        next(
          setMessage({
            messageType: MessageTypes.error,
            messageContent:
              localizations?.GET_STUDENT_ASSIGNMENTS_ERROR_MSG ??
              GET_STUDENT_ASSIGNMENTS_ERROR_MSG,
            feature: GET_STUDENT_ASSIGNMENTS,
          })
        );
        break;
      case MATERIAL_TASK_COMPLETED:
        next(setLoader({ state: true, feature: MATERIAL_TASK_COMPLETED }));
        next(
          apiRequest({
            method: 'GET',
            url: `${MATERIAL_TASK_COMPLETED_URL}/${action.payload.taskId}`,
            feature: MATERIAL_TASK_COMPLETED,
          })
        );
        break;
      case `${MATERIAL_TASK_COMPLETED} ${API_SUCCESS}`:
        next(setLoader({ state: false, feature: MATERIAL_TASK_COMPLETED }));
        const copyOfMyAssignedBooksForMaterialCompletion = _.cloneDeepWith(
          data.myAssignedBooks
        );
        let materialTaskPath = [];
        copyOfMyAssignedBooksForMaterialCompletion.books.forEach(
          (book, bookIndex) => {
            book.tasks.forEach((task, taskIndex) => {
              task.elements.forEach((element, elementIndex) => {
                if (element.taskId === action.payload.task.id) {
                  materialTaskPath = [
                    'books',
                    bookIndex,
                    'tasks',
                    taskIndex,
                    'elements',
                    elementIndex,
                    'isCompleted',
                  ];
                }
              });
            });
          }
        );

        if (materialTaskPath.length > 0) {
          _.set(
            copyOfMyAssignedBooksForMaterialCompletion,
            materialTaskPath,
            action.payload.task.isCompleted
          );
          materialTaskPath = [];
        }

        next(
          setData({
            data: {
              myAssignedBooks: copyOfMyAssignedBooksForMaterialCompletion,
            },
            feature: MATERIAL_TASK_COMPLETED,
          })
        );
        break;
      case `${MATERIAL_TASK_COMPLETED} ${API_ERROR}`:
        next(setLoader({ state: false, feature: MATERIAL_TASK_COMPLETED }));
        next(
          sendLog({
            product: PRODUCT_TYPE,
            title: `${MATERIAL_TASK_COMPLETED} ${API_ERROR}`,
            detail: MATERIAL_TASK_COMPLETED_ERROR_MSG,
            severity: SeverityTypes.major,
          })
        );
        next(
          sendLog({
            product: PRODUCT_TYPE,
            title: `${MATERIAL_TASK_COMPLETED} ${API_ERROR}`,
            detail: action.payload,
            severity: SeverityTypes.major,
          })
        );
        break;
      case GET_QUIZ_DETAIL:
        if (
          action.payload.quizId === null ||
          action.payload.quizId === undefined ||
          action.payload.quizId === 0
        ) {
          next(
            setData({
              data: { quiz: undefined },
              feature: GET_QUIZ_DETAIL,
            })
          );
        } else {
          next(
            apiRequest({
              method: 'GET',
              url: `${GET_QUIZ_DETAIL_URL}/${action.payload.quizId}`,
              feature: GET_QUIZ_DETAIL,
            })
          );
        }

        break;
      case `${GET_QUIZ_DETAIL} ${API_SUCCESS}`:
        next(
          setData({
            data: { quiz: action.payload?.quiz },
            feature: GET_QUIZ_DETAIL,
          })
        );
        break;
      case `${GET_QUIZ_DETAIL} ${API_ERROR}`:
        next(
          setNotification({
            type: MessageTypes.error,
            message: GET_QUIZ_DETAIL_ERROR_MSG,
            feature: GET_QUIZ_DETAIL,
          })
        );
        break;
      case SEND_QUIZ_RESULT:
        // next(setLoader({ state: true, feature: SEND_QUIZ_RESULT }));
        next(
          apiRequest({
            body: { quizResult: action.payload },
            method: 'POST',
            url: SEND_QUIZ_RESULT_URL,
            feature: SEND_QUIZ_RESULT,
          })
        );
        break;
      case `${SEND_QUIZ_RESULT} ${API_SUCCESS}`:
        const copyOfMyAssignedBooks = _.cloneDeepWith(data.myAssignedBooks);
        let path = [];
        action.payload.quizResult.tasks.forEach((resultTask) => {
          copyOfMyAssignedBooks.books.forEach((book, bookIndex) => {
            if (book.id === action.payload.quizResult.bookId) {
              book.tasks.forEach((task, taskIndex) => {
                task.elements.forEach((element, elementIndex) => {
                  if (element.taskId === resultTask.id) {
                    path = [
                      'books',
                      bookIndex,
                      'tasks',
                      taskIndex,
                      'elements',
                      elementIndex,
                      'isCompleted',
                    ];
                  }
                });
              });
            }
          });

          if (path.length > 0) {
            _.set(copyOfMyAssignedBooks, path, resultTask.isCompleted);
            path = [];
          }
        });
        next(
          setData({
            data: { myAssignedBooks: copyOfMyAssignedBooks },
            feature: SEND_QUIZ_RESULT,
          })
        );

        // Update my books
        if (
          action.payload.quizResult.isQuizPrimary === true &&
          data.myBooks?.books?.length > 0
        ) {
          const bookIndex = data.myBooks.books.findIndex(
            (book) => book.id === action.payload.quizResult.bookId
          );
          if (bookIndex >= 0) {
            const copyOfMyBooks = _.cloneDeepWith(data.myBooks);
            _.set(copyOfMyBooks, ['books', bookIndex, 'quizSolved'], true);
            next(
              setData({
                data: {
                  myBooks: copyOfMyBooks,
                },
                feature: SEND_QUIZ_RESULT,
              })
            );
          }
        }
        break;
      case `${SEND_QUIZ_RESULT} ${API_ERROR}`:
        next(
          sendLog({
            product: PRODUCT_TYPE,
            title: `${SEND_QUIZ_RESULT} ${API_ERROR}`,
            detail: SEND_QUIZ_RESULT_ERROR_MSG,
            severity: SeverityTypes.major,
          })
        );
        next(
          sendLog({
            product: PRODUCT_TYPE,
            title: `${SEND_QUIZ_RESULT} ${API_ERROR}`,
            detail: action.payload,
            severity: SeverityTypes.major,
          })
        );
        break;
      case GET_AUDIO_UPLOAD_PARAMS:
        next(setLoader({ state: true, feature: GET_AUDIO_UPLOAD_PARAMS }));
        next(
          apiRequest({
            method: 'POST',
            body: action.payload,
            url: GET_AUDIO_UPLOAD_PARAMS_URL,
            feature: GET_AUDIO_UPLOAD_PARAMS,
          })
        );
        break;
      case `${GET_AUDIO_UPLOAD_PARAMS} ${API_SUCCESS}`:
        next(setLoader({ state: false, feature: GET_AUDIO_UPLOAD_PARAMS }));
        next(
          setData({ data: action.payload, feature: GET_AUDIO_UPLOAD_PARAMS })
        );
        next(
          setNotification({
            type: MessageTypes.success,
            message: GET_AUDIO_UPLOAD_PARAMS_SUCCESS_MSG,
            feature: GET_AUDIO_UPLOAD_PARAMS,
          })
        );
        break;
      case `${GET_AUDIO_UPLOAD_PARAMS} ${API_ERROR}`:
        next(setLoader({ state: false, feature: GET_AUDIO_UPLOAD_PARAMS }));
        next(
          setNotification({
            type: MessageTypes.error,
            message: GET_AUDIO_UPLOAD_PARAMS_ERROR_MSG,
            feature: GET_AUDIO_UPLOAD_PARAMS,
          })
        );
        break;
      case REMOVE_AUDIO_UPLOAD_PARAMS:
        const where = 'uploadParameters';
        next(
          removeData({
            feature: REMOVE_AUDIO_UPLOAD_PARAMS,
            where,
          })
        );
        break;
      case START_STUDENT_REPORTS_PAGE:
        if (ui && ui.redirectTo && ui.redirectTo === STUDENT_REPORTS_ROUTE) {
          next(
            setRedirect({
              to: '',
              feature: START_STUDENT_REPORTS_PAGE,
            })
          );
        }
        break;
      case GET_STUDENT_REPORTS:
        next(setLoader({ state: true, feature: GET_STUDENT_REPORTS }));
        next(
          apiRequest({
            method: 'GET',
            url: GET_STUDENT_REPORTS_URL,
            feature: GET_STUDENT_REPORTS,
          })
        );
        break;
      case `${GET_STUDENT_REPORTS} ${API_SUCCESS}`:
        next(setLoader({ state: false, feature: GET_STUDENT_REPORTS }));
        next(
          setData({
            data: { reports: { ...data.reports, ...action.payload } },
            feature: GET_STUDENT_REPORTS,
          })
        );
        break;
      case `${GET_STUDENT_REPORTS} ${API_ERROR}`:
        next(setLoader({ state: false, feature: GET_STUDENT_REPORTS }));
        next(
          setMessage({
            messageType: MessageTypes.error,
            messageContent:
              localizations?.GET_STUDENT_REPORTS_ERROR_MSG ??
              GET_STUDENT_REPORTS_ERROR_MSG,
            feature: GET_STUDENT_REPORTS,
          })
        );

        next(
          sendLog({
            product: PRODUCT_TYPE,
            title: `${GET_STUDENT_REPORTS} ${API_ERROR}`,
            detail: action.payload,
            severity: SeverityTypes.major,
          })
        );
        break;
      case GET_STUDENT_TOTAL_POINT:
        next(setLoader({ state: true, feature: GET_STUDENT_TOTAL_POINT }));
        next(
          apiRequest({
            method: 'GET',
            url: GET_STUDENT_TOTAL_POINT_URL,
            feature: GET_STUDENT_TOTAL_POINT,
          })
        );
        break;
      case `${GET_STUDENT_TOTAL_POINT} ${API_SUCCESS}`:
        next(setLoader({ state: false, feature: GET_STUDENT_TOTAL_POINT }));
        next(
          setData({
            data: { reports: { ...data.reports, ...action.payload } },
            feature: GET_STUDENT_TOTAL_POINT,
          })
        );
        break;
      case `${GET_STUDENT_TOTAL_POINT} ${API_ERROR}`:
        next(setLoader({ state: false, feature: GET_STUDENT_TOTAL_POINT }));
        next(
          setNotification({
            type: MessageTypes.error,
            message: GET_STUDENT_TOTAL_POINT_ERROR_MSG,
            feature: GET_STUDENT_TOTAL_POINT,
          })
        );
        next(
          sendLog({
            product: PRODUCT_TYPE,
            title: `${GET_STUDENT_TOTAL_POINT} ${API_ERROR}`,
            detail: action.payload,
            severity: SeverityTypes.major,
          })
        );
        break;
      default:
        break;
    }
  };
