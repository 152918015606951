import { PAGES } from 'redux/actions/pages/constants';

// feature name
export const PUSULA_FEATURES = `${PAGES} [Pusula]`;

// action types
export const VALIDATE_PUSULA_STUDENT_KEY = `${PUSULA_FEATURES} VALIDATE PUSULA STUDENT KEY`;
export const VALIDATE_PUSULA_TEACHER_KEY = `${PUSULA_FEATURES} VALIDATE PUSULA TEACHER KEY`;

// action creators
export const validatePusulaStudentKey = ({ studentKey }) => ({
  type: VALIDATE_PUSULA_STUDENT_KEY,
  payload: { key: studentKey, userType: 'STUDENT' },
});

export const validatePusulaTeacherKey = ({ teacherKey }) => ({
  type: VALIDATE_PUSULA_TEACHER_KEY,
  payload: { key: teacherKey, userType: 'OTHER' },
});
