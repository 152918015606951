import React, { useEffect } from 'react';
import { BrowserRouter, Route, Redirect, Switch } from 'react-router-dom';
// pages
import AboutUs from 'views/examples/AboutUs.js';
// import BlogPost from 'views/examples/BlogPost.js';
// import BlogPosts from 'views/examples/BlogPosts.js';
import ContactUs from 'views/examples/ContactUs.js';
// import Ecommerce from 'views/examples/Ecommerce.js';
// import Index from 'views/Index.js';
// import LandingPage from 'views/examples/LandingPage.js';
import LoginPage from 'views/LoginPage.js';
// import NucleoIcons from 'views/NucleoIcons.js';
// import Presentation from 'views/Presentation.js';
// import Pricing from 'views/examples/Pricing.js';
// import ProductPage from 'views/examples/ProductPage.js';
// import ProfilePage from 'views/examples/ProfilePage.js';
// import Sections from 'views/Sections.js';
import SignupPage from 'views/examples/SignupPage.js';
import ReadBook from 'views/ReadBook';
import { connect } from 'react-redux';
import CreatePassword from 'views/CreatePassword';
import GetLocalStorage from 'views/GetLocalStorage';
import StudentHomePage from 'views/StudentHomePage';
import StudentMessages from 'views/StudentMessages';
import Kvkk from 'views/Kvkk';
import KvkkAndroid from 'views/KvkkAndroid';
import TermsOfUse from 'views/TermsOfUse';
// Redux
import { startAuth } from 'redux/actions/auth';
import { startLocalization } from 'redux/actions/localization';
import BookList from 'views/BookList';
import Home from 'views/Home';
import ForgotPassword from 'views/ForgotPassword';
// import PlatformPage from 'views/PlatformPage';
import ContactPage from 'views/ContactPage';
import BuyNowPage from 'views/BuyNowPage';
import ErrorBoundary from 'ErrorBoundary';
import StudentBooks from 'views/StudentBooks';
import StudentAssignments from 'views/StudentAssignments';
import StudentReports from 'views/student-reports';
import HomeOldDesign from 'views/HomeOldDesign';
import Vcloud from 'views/Vcloud';
import Pusula from 'views/Pusula';
//redeploy
const App = ({ startApplication, startLocalization, organizationId }) => {
  useEffect(() => {
    startApplication();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    startLocalization();
    // eslint-disable-next-line
  }, [organizationId]);
  return (
    <BrowserRouter>
      <ErrorBoundary>
        <Switch>
          <Route
            path="/getlocalstorage"
            render={(props) => <GetLocalStorage {...props} />}
          />
          <Route path="/read/:id" render={(props) => <ReadBook {...props} />} />
          <Route
            path="/book-list"
            render={(props) => <BookList {...props} />}
          />
          <Route path="/about-us" render={(props) => <AboutUs {...props} />} />
          {/* <Route
            path="/blog-post"
            render={(props) => <BlogPost {...props} />}
          /> */}
          {/* <Route
            path="/blog-posts"
            render={(props) => <BlogPosts {...props} />}
          /> */}
          <Route
            path="/contact-us"
            render={(props) => <ContactUs {...props} />}
          />
          {/* <Route
            path="/e-commerce"
            render={(props) => <Ecommerce {...props} />}
          /> */}
          {/* <Route path="/index" render={(props) => <Index {...props} />} /> */}
          {/* <Route
            path="/landing-page"
            render={(props) => <LandingPage {...props} />}
          /> */}
          {/* <Route path="/signin" render={(props) => <LoginPage {...props} />} /> */}
          {/* <Route
            path="/nucleo-icons"
            render={(props) => <NucleoIcons {...props} />}
          /> */}
          {/* <Route
            path="/presentation"
            render={(props) => <Presentation {...props} />}
          /> */}
          {/* <Route path="/pricing" render={(props) => <Pricing {...props} />} /> */}
          {/* <Route
            path="/product-page"
            render={(props) => <ProductPage {...props} />}
          /> */}
          {/* <Route
            path="/profile-page"
            render={(props) => <ProfilePage {...props} />}
          />
          <Route path="/sections" render={(props) => <Sections {...props} />} /> */}
          <Route
            path="/create-password"
            render={(props) => <CreatePassword {...props} />}
          />
          <Route
            path="/forgot-password"
            render={(props) => <ForgotPassword {...props} />}
          />
          <Route path="/signin" render={(props) => <LoginPage {...props} />} />
          <Route
            path="/sign-up"
            render={(props) => <SignupPage {...props} />}
          />
          <Route
            path="/buy-now"
            render={(props) => <BuyNowPage {...props} />}
          />
          <Route
            path="/contact"
            render={(props) => <ContactPage {...props} />}
          />
          {/* <Route
            path="/platform"
            render={(props) => <PlatformPage {...props} />}
          /> */}
          <Route
            path="/student"
            render={(props) => <StudentHomePage {...props} />}
          />
          <Route
            path="/messages"
            render={(props) => <StudentMessages {...props} />}
          />
          <Route
            path="/books"
            render={(props) => <StudentBooks {...props} />}
          />
          <Route
            path="/assignments"
            render={(props) => <StudentAssignments {...props} />}
          />
          <Route
            path="/reports"
            render={(props) => <StudentReports {...props} />}
          />
          <Route path="/kvkk" render={(props) => <Kvkk {...props} />} />
          <Route
            path="/kvkk-android"
            render={(props) => <KvkkAndroid {...props} />}
          />
          <Route
            path="/terms-of-use"
            render={(props) => <TermsOfUse {...props} />}
          />

          <Route path="/vcloud" render={(props) => <Vcloud {...props} />} />

          <Route path="/pusula" render={(props) => <Pusula {...props} />} />

          <Route exact path="/" render={(props) => <Home {...props} />} />
          <Route
            exact
            path="/old-design"
            render={(props) => <HomeOldDesign {...props} />}
          />
          <Redirect from="*" to="/" />
        </Switch>
      </ErrorBoundary>
    </BrowserRouter>
  );
};

const mapStateToProps = (state) => ({
  organizationId: state.header.organization,
});

const mapDispatchToProps = { startApplication: startAuth, startLocalization };

export default connect(mapStateToProps, mapDispatchToProps)(App);
