import React, { /* createElement, */ useEffect } from 'react';
import { connect } from 'react-redux';
import { useLocation, useHistory } from 'react-router-dom';
import PacmanLoader from 'react-spinners/PacmanLoader';
import Swal from 'sweetalert2';

import {
  validatePusulaStudentKey,
  validatePusulaTeacherKey,
  PUSULA_FEATURES,
} from 'redux/actions/pages';
import { removeMessage } from 'redux/actions/message';
import { BASE_URL } from 'redux/actions/constants';

const Pusula = ({
  validateStudent,
  validateTeacher,
  redirectTo,
  message,
  removeMessageElement,
}) => {
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const studentKey = searchParams.get('studentKey');
  const teacherKey = searchParams.get('teacherKey');

  const history = useHistory();

  useEffect(() => {
    if (studentKey) {
      validateStudent({ studentKey });
    } else if (teacherKey) {
      validateTeacher({ teacherKey });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [studentKey, teacherKey]);

  useEffect(() => {
    if (redirectTo && redirectTo !== '' && redirectTo !== '/pusula') {
      history.push(redirectTo);
    }
    // eslint-disable-next-line
  }, [redirectTo]);

  useEffect(() => {
    if (
      message &&
      message.messageContent !== undefined &&
      message.messageContent !== null &&
      message.messageContent !== ''
    ) {
      Swal.fire({
        icon: message.messageType || 'error',
        title: 'Nasıl devam etmek istiyorsun?',
        text: 'Mobil cihaz kullanıyorsun. Nasıl devam etmek istersin?',
        showCancelButton: true,
        confirmButtonText: 'Mobil uygulamada aç',
        cancelButtonText: 'Tarayıcıdan devam et',
      }).then((result) => {
        if (result.isConfirmed) {
          if (message.messageContent?.isIOS) {
            let newWindow = window.open('about:blank', '_blank');
            newWindow.location.href = `${BASE_URL}/sso/?token=${message.messageContent?.token}`;
          } else {
            window.location.href = `${BASE_URL}/sso/?token=${message.messageContent?.token}`;
          }
        } else if (result.isDismissed) {
          window.location.href = BASE_URL;
        }

        removeMessageElement(PUSULA_FEATURES);
      });
    }
    // eslint-disable-next-line
  }, [message]);

  return (
    <div className="loading whole-screen" style={{ zIndex: 0 }}>
      <PacmanLoader color="#67BCEC" size={50} />
    </div>
  );
};

const mapStateToProps = (state) => ({
  redirectTo: state.ui.redirectTo,
  message: state.message[PUSULA_FEATURES],
});

const mapDispatchToProps = {
  validateStudent: validatePusulaStudentKey,
  validateTeacher: validatePusulaTeacherKey,
  removeMessageElement: removeMessage,
};

export default connect(mapStateToProps, mapDispatchToProps)(Pusula);
