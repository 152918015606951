/**
 * Usage:
 * const language: "TR";
 * const errorKey: "SAVE_AUDIO_ERROR_MSG";
 *
 * const error = backendErrorMapper[language][errorKey];
 */

export const backendErrorMapper = {
  TR: {
    GENERAL_ERROR_MSG: 'İşlem sırasında hata meydana geldi.',
    NOT_FOUND_ERROR_MSG: 'Bulunamadı.',
    INTERNAL_ERROR_MSG: 'Sistem hatası.',
    INVALID_REQUEST_MSG: 'Girilen parametreler geçersiz.',
    NOT_AUTHENTICATED_MSG: 'Kimlik doğrulanamadı.',
    NOT_AUTHORIZED_MSG: 'Yetki doğrulanamadı.',
    LOCALIZATION_ERROR_MSG:
      'Localization listesi getirilirken hata meydana geldi.',
    NAME_NULL_MSG: 'İsim alanı boş olamaz.',
    SCHOOL_NULL_MSG: 'Okul ismi boş olamaz.',
    CITY_NOT_SELECTED_MSG: 'Şehir seçilmek zorundadır.',
    EMAIL_NULL_MSG: 'Eposta alanı boş olamaz.',
    EMAIL_INVALID_MSG: 'Geçersiz eposta adresi.',
    EMAIL_REQUIRED_MSG:
      'Kullanıcı giriş için eposta kullanmadığınız için şifre yenileme yapılamamaktadır. Lütfen yeni şifre için öğretmenininize başvurunuz.',
    PASSWORD_NULL_MSG: 'Şifre alanı boş olamaz.',
    PASSWORD2_NULL_MSG: 'Şifre tekrarı alanı boş olamaz.',
    MESSAGE_NULL_MSG: 'Mesaj alanı boş olamaz.',
    SIGNIN_ERROR1_MSG: 'Kullanıcı giriş bilgileri geçersiz.',
    SIGNIN_ERROR2_MSG: 'Kullanıcı girişi gerçekleştirilemiyor.',
    SIGNIN_ERROR3_MSG: 'Kullanıcı giriş bileti üretilemiyor.',
    SIGNIN_ERROR4_MSG: 'vCloud girişi gerçekleştirilemiyor.',
    CLIENTID_NULL_MSG: 'Client ID boş olamaz.',
    SIGNIN_ERROR5_MSG: 'vCloud desteklenmiyor',
    SIGNIN_ERROR6_MSG: 'vCloud user oluştutulamadı.',
    SIGNIN_ERROR7_MSG: 'vCloud sınıf oluştutulamadı.',
    SIGNIN_ERROR8_MSG: 'vCloud sınıf user oluştutulamadı.',
    SIGNIN_ERROR9_MSG: 'vCloud suborganization user oluştutulamadı.',
    SIGNIN_ERROR10_MSG: 'vCloud tanımsız user tipi.',
    VALIDATE_EMAIL_TOKEN_ERROR1_MSG: 'Eposta linki geçersiz.',
    VALIDATE_EMAIL_TOKEN_ERROR2_MSG: 'Eposta linki doğrulanamadı.',
    VALIDATE_EMAIL_TOKEN_ERROR3_MSG:
      'Bağlantı linki doğrulaması sırasında hata meydana geldi.',
    EMAIL_TOKEN_NULL_MSG:
      'Bilet geçersiz. Eposta linki üzerinden şifre değiştirilebilir.',
    RESET_PASSWORD_ERROR1_MSG: 'Şifre ve tekrarı aynı olmak zorundadır.',
    RESET_PASSWORD_ERROR2_MSG: 'Şifre değiştirme sırasında hata oluştu.',
    USER_NOT_VERIFIED_MSG: 'Kullanıcı doğrulanamadı.',
    RESET_PASSWORD_ERROR4_MSG: 'Şifre değiştirme sırasında hata meydana geldi.',
    GET_BOOK_WITH_USER_RELEATED_DETAILS_ERROR_MSG:
      'Kullanıcı detaylarını da içeren kitap bilgisi alınırken hata meydana geldi.',
    SET_BOOKMARK_ERROR_MSG: 'Yer imi eklenirken hata meydana geldi.',
    REMOVE_BOOKMARK_ERROR_MSG: 'Yer imi kaldırılırken hata meydana geldi.',
    SAVE_AUDIO_ERROR_MSG: 'Ses dosyası kaydedilirken hata meydana geldi.',
    SET_LAST_PAGE_ERROR_MSG:
      'Son sayfa bilgisi iletilirken hata meydana geldi.',
    GET_ALL_BOOKS_WITH_COVER: 'Kitap listesi getirilirken hata meydana geldi.',
    SEND_EMAIL_ERROR_MSG:
      'Eposta gönderme işlemi sırasında hata meydana geldi.',
    NEW_ASSIGNMENT_ERROR: 'Yeni görev oluşturulurken hata meydana geldi.',
    NEW_USER_ASSIGNMENT_ERROR: 'Öğrenciye görev atanırken hata meydana geldi.',
    NEW_TASK_ERROR: 'Yeni task oluşturulurken hata meydana geldi.',
    TASK_ASSIGN_USER_ERROR: 'Öğrenciye task atanırken hata meydana geldi.',
    GET_ALL_ASSIGNMENT_BY_CREATED_BY_ERROR:
      'Tüm görevler getirilirken hata meydana geldi.',
    GET_ASSIGNMENT_DETAIL_ERROR:
      'Görev detayı getirilirken hata meydana geldi.',
    TOKEN_NULL_MSG: 'Token Eksik',
    KEY_NULL_MSG: 'Key Eksik',
    USER_TYPE_NULL_MSG: 'Kullanıcı Tipi Eksik.',
  },

  EN: {
    GENERAL_ERROR_MSG: 'An error occured during the process.',
    NOT_FOUND_ERROR_MSG: 'Not found.',
    INTERNAL_ERROR_MSG: 'Internal error.',
    INVALID_REQUEST_MSG: 'Parameters entered are not valid.',
    NOT_AUTHENTICATED_MSG: 'Not authenticated.',
    NOT_AUTHORIZED_MSG: 'Forbidden, not authorized.',
    LOCALIZATION_ERROR_MSG: 'Error occurred while getting localization list.',
    NAME_NULL_MSG: 'Name can not be null.',
    SCHOOL_NULL_MSG: 'School name can not be null.',
    CITY_NOT_SELECTED_MSG: 'City must be selected.',
    EMAIL_NULL_MSG: 'Email can not be null.',
    EMAIL_INVALID_MSG: 'Email is invalid.',
    EMAIL_REQUIRED_MSG:
      'Since you do not use email for the user login, password renewal cannot be made. Please contact your teacher for the new password.',
    PASSWORD_NULL_MSG: 'Password can not be null.',
    PASSWORD2_NULL_MSG: 'Password2 can not be null.',
    MESSAGE_NULL_MSG: 'Message can not be null.',
    SIGNIN_ERROR1_MSG: 'User credentials are invalid.',
    SIGNIN_ERROR2_MSG: 'Unable to user login.',
    SIGNIN_ERROR3_MSG: 'Unable to generate user token.',
    SIGNIN_ERROR4_MSG: 'Unable to login to vCloud',
    SIGNIN_ERROR5_MSG: 'vCloud is not supported',
    SIGNIN_ERROR6_MSG: 'vCloud user could not be created.',
    SIGNIN_ERROR7_MSG: 'vCloud class could not be created.',
    SIGNIN_ERROR8_MSG: 'vCloud class user could not be created.',
    SIGNIN_ERROR9_MSG: 'vCloud suborganization user could not be created.',
    SIGNIN_ERROR10_MSG: 'vCloud undefined user type.',
    VALIDATE_EMAIL_TOKEN_ERROR1_MSG: 'Invalid email link.',
    VALIDATE_EMAIL_TOKEN_ERROR2_MSG: 'Email link could not be verified.',
    VALIDATE_EMAIL_TOKEN_ERROR3_MSG:
      'Error occurred during email link verification.',
    EMAIL_TOKEN_NULL_MSG:
      'Token is invalid. Password can be changed via email link.',
    RESET_PASSWORD_ERROR1_MSG: 'Password and password2 must be identical.',
    RESET_PASSWORD_ERROR2_MSG: 'Error occurred while changing password.',
    USER_NOT_VERIFIED_MSG: 'User could not be verified.',
    RESET_PASSWORD_ERROR4_MSG: 'Error occurred during password change.',
    GET_BOOK_WITH_USER_RELEATED_DETAILS_ERROR_MSG:
      'The error has occurred while getting book information containing the user details.',
    SET_BOOKMARK_ERROR_MSG: 'An error occurred while adding a bookmark.',
    REMOVE_BOOKMARK_ERROR_MSG: 'An error occurred while removing the bookmark.',
    SAVE_AUDIO_ERROR_MSG: 'Error occurred while saving audio file.',
    SET_LAST_PAGE_ERROR_MSG: 'Error occurred while setting last page.',
    GET_ALL_BOOKS_WITH_COVER: 'Error occurred while getting book list.',
    SEND_EMAIL_ERROR_MSG: 'Error occurred during email sending.',
    NEW_ASSIGNMENT_ERROR: 'An error occurred while creating a new assignment.',
    NEW_USER_ASSIGNMENT_ERROR:
      'An error occurred while assigning a assignment to the student.',
    NEW_TASK_ERROR: 'Yeni task oluşturulurken hata meydana geldi.',
    TASK_ASSIGN_USER_ERROR: 'An error occurred while creating a new task.',
    GET_ALL_ASSIGNMENT_BY_CREATED_BY_ERROR:
      'An error occurred while fetching all assignments.',
    GET_ASSIGNMENT_DETAIL_ERROR:
      'An error occurred while fetching the assignment detail.',
    TOKEN_NULL_MSG: 'Token Missing',
    CLIENTID_NULL_MSG: 'Client ID can not be null.',
    KEY_NULL_MSG: 'Key Missing',
    USER_TYPE_NULL_MSG: 'User Type Missing.',
  },
};
