import React, { /* createElement, */ useEffect } from 'react';
import { connect } from 'react-redux';
import { useLocation, useHistory } from 'react-router-dom';
import PacmanLoader from 'react-spinners/PacmanLoader';
import Swal from 'sweetalert2';

import { validateVcloudToken, VCLOUD_FEATURES } from 'redux/actions/pages';
import { removeMessage } from 'redux/actions/message';
import { BASE_URL } from 'redux/actions/constants';

const Vcloud = ({ validate, redirectTo, message, removeMessageElement }) => {
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const token = searchParams.get('token');
  const clientid = searchParams.get('clientid');

  const history = useHistory();

  useEffect(() => {
    validate({ token, clientid });
    // 1. token ve client id varsa
    //   1.1. token ve client id'yi doğrulaması için backend'e gönder
    //   1.2. token ve client id doğrulandıysa
    //     1.2.1. auth reducer'a currentUser ve token bilgileri gönder
    //     1.2.2. header reducer değerlerini güncelle
    //     1.2.3. TOKEN'ı local storage'a kaydet
    //     1.2.4. kullanıcı tipine göre yönlendirme yap
    //       1.2.4.1. öğrenci ise /student'a yönlendir.
    //       1.2.4.2. öğretmen ise accounts'a yönlendir.
    //       1.2.4.3. diğerleri için / sayfasına yönlendir.
    //   1.3. token ve client id doğrulanamadıysa
    //     1.3.1. giriş işlemi başarısız oldu sayfasına yönlendir
    // 2 .token ve client id yoksa
    //   2.1. giriş işlemi başarısız oldu sayfasına yönlendir

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientid, token]);

  useEffect(() => {
    if (redirectTo && redirectTo !== '' && redirectTo !== '/vcloud') {
      history.push(redirectTo);
    }
    // eslint-disable-next-line
  }, [redirectTo]);

  // useEffect(() => {
  //   if (
  //     message &&
  //     message.messageContent !== undefined &&
  //     message.messageContent !== null &&
  //     message.messageContent !== ''
  //   ) {
  //     Swal.fire({
  //       icon: message.messageType || 'error',
  //       title: 'Nasıl devam etmek istiyorsun?',
  //       text: 'Mobil uygulamadan devam çok yakında aktif olacak. Şimdilik tarayıcıdan devam edebilirsiniz.',
  //       showCancelButton: false,
  //       confirmButtonText: 'Tarayıcıdan devam et',
  //     }).then((result) => {
  //       if (result.isConfirmed) {
  //         window.location.href = BASE_URL;
  //       }

  //       removeMessageElement(VCLOUD_FEATURES);
  //     });
  //   }
  //   // eslint-disable-next-line
  // }, [message]);

  useEffect(() => {
    if (
      message &&
      message.messageContent !== undefined &&
      message.messageContent !== null &&
      message.messageContent !== ''
    ) {
      Swal.fire({
        icon: message.messageType || 'error',
        title: 'Nasıl devam etmek istiyorsun?',
        text: 'Mobil cihaz kullanıyorsun. Nasıl devam etmek istersin?',
        showCancelButton: true,
        confirmButtonText: 'Mobil uygulamada aç',
        cancelButtonText: 'Tarayıcıdan devam et',
        // footer:
        //   '<a href="' +
        //   `${BASE_URL}/sso/?token=${message.messageContent}` +
        //   '">Mobil Uygulamada Aç</a>',
      }).then((result) => {
        if (result.isConfirmed) {
          if (message.messageContent?.isIOS) {
            let newWindow = window.open('about:blank', '_blank');
            newWindow.location.href = `${BASE_URL}/sso/?token=${message.messageContent?.token}`;
          } else {
            window.location.href = `${BASE_URL}/sso/?token=${message.messageContent?.token}`;
          }
        } else if (result.isDismissed) {
          window.location.href = BASE_URL;
        }

        removeMessageElement(VCLOUD_FEATURES);
      });
    }
    // eslint-disable-next-line
  }, [message]);

  return (
    <div className="loading whole-screen" style={{ zIndex: 0 }}>
      <PacmanLoader color="#67BCEC" size={50} />
    </div>
  );
};

const mapStateToProps = (state) => ({
  redirectTo: state.ui.redirectTo,
  message: state.message[VCLOUD_FEATURES],
});

const mapDispatchToProps = {
  validate: validateVcloudToken,
  removeMessageElement: removeMessage,
};

export default connect(mapStateToProps, mapDispatchToProps)(Vcloud);
